import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, Divider, Popup, Icon } from 'semantic-ui-react'
import { Row } from 'react-table'
import { useBoolean } from 'usehooks-ts'
import dayjs from 'dayjs'
import { useGetFeatureFlagReportQuery } from '../../../queries/GetNewFeatureFlagReportQuery'
import { useGetRegionQuery } from '../../../queries/GetRegionsQuery'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import HeaderWithControl from '../../../components/TableComponents/BaseTableComponents/HeaderWithControl'
import { PackageInstallationModal } from '../../package/component/PackageInstallationModal'
import { PackageInstallationStatus } from '../../package/component/PackageInstallationStatus'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'
import { InstalledPackage, InstalledPackageParams } from '../../../actions/Package'
import { useGetInstalledPackageQuery } from '../../../queries/packages/GetInstalledPackageQuery'
import { DatadogPackageLogsButton } from './DatadogPackageLogsButton'

interface PackagesListProps {
  teamId: string
}

interface PackageCellProps {
  isDevelopmentPackage: boolean
  content: string
  url: string
}

export const PackageCell = ({ isDevelopmentPackage, content, url }: PackageCellProps) =>
  isDevelopmentPackage ? <>{content}</> : <Link to={url}>{content}</Link>

export const PackagesList = ({ teamId }: PackagesListProps) => {
  const [includeAllStatus, setIncludeAllStatus] = useState(true)
  const [includeDev, setIncludeDev] = useState(true)
  const { data: team, isLoading: isLoadingTeam } = useGetTeamQuery(teamId)
  const { value: disableInstallPackages, setTrue, setFalse } = useBoolean(true)
  const { data: regionInfo, isFetching: isLoadingRegion } = useGetRegionQuery()
  const teamApi = regionInfo?.find(r => r.region.toLowerCase() === team?.region.toLowerCase())?.server.api

  const {
    data: features,
    isFetching: isLoadingFlags,
    refetch: refetchFlags
  } = useGetFeatureFlagReportQuery(team ? [{ team, api: teamApi ?? '' }] : undefined)

  useEffect(() => {
    refetchFlags()
    const teamFlags = features?.[0]?.featureFlags
    const hasFlagEnabled = teamFlags?.find(f => f.name === 'usePackagingV2')?.value
    hasFlagEnabled ? setFalse() : setTrue()
  }, [features, team, regionInfo])

  const installedPackageParams: InstalledPackageParams = {
    tenantId: team?.tenantId,
    includeAllStatus,
    includeDev
  }
  const {
    data: installedPackages,
    isLoading: isLoadingInstalledPackage,
    refetch
  } = useGetInstalledPackageQuery(installedPackageParams, team?.region ?? '')

  const tableColumns = createMemoizedColumns<InstalledPackage>(
    [
      {
        Header: 'Name',
        accessor: 'packageName',
        Cell: ({ row }: { row: Row<InstalledPackage> }) => (
          <PackageCell
            isDevelopmentPackage={row.original.packageName.startsWith('_local')}
            content={row.original.packageName}
            url={`/packages/${encodeURIComponent(row.original.packageName)}`}
          />
        )
      },
      {
        Header: 'Version',
        accessor: 'packageVersion',
        Cell: ({ row }: { row: Row<InstalledPackage> }) => (
          <PackageCell
            isDevelopmentPackage={row.original.packageName.startsWith('_local')}
            content={row.original.packageVersion}
            url={`/packages/${encodeURIComponent(row.original.packageName)}/${encodeURIComponent(
              row.original.packageVersion
            )}`}
          />
        )
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: { row: Row<InstalledPackage> }) => <PackageInstallationStatus status={row.original.status} />
      },
      {
        Header: 'Logs',
        accessor: 'deploymentId',
        Cell: ({ row }: { row: Row<InstalledPackage> }) => (
          <DatadogPackageLogsButton
            packageName={row.original.packageName}
            deploymentId={row.original.deploymentId}
            tenantId={row.original.tenantId}
            startTime={dayjs(row.original.createdAt).valueOf()}
            endTime={
              row.original.completedAt
                ? dayjs(row.original.completedAt).valueOf() + 120000 // add two minutes of padding to end time as messages do show up afterwards
                : undefined
            }
          />
        )
      }
    ],
    []
  )

  return (
    <>
      <HeaderWithControl
        title="Installed packages"
        size="h3"
        control={
          <PackageInstallationModal
            tenantId={team?.tenantId ?? ''}
            region={team?.region ?? ''}
            disableInstallPackages={disableInstallPackages}
            isFetching={isLoadingFlags || isLoadingRegion || isLoadingTeam}
            teamName={team?.primaryName ?? ''}
          />
        }
      />
      <Divider />
      <Checkbox
        id="IncludeAllStatus"
        label="Include all statuses"
        toggle
        checked={includeAllStatus}
        onClick={() => {
          setIncludeAllStatus(!includeAllStatus)
          refetch()
        }}
        style={{ padding: '4px' }}
      />
      <Checkbox
        id="IncludeDev"
        label="Include local packages"
        toggle
        checked={includeDev}
        onClick={() => {
          setIncludeDev(!includeDev)
          refetch()
        }}
        style={{ padding: '4px', marginLeft: '10px' }}
      />
      <Popup
        trigger={<Icon name="question circle" />}
        content={<div>Local packages are those whose names start with _local/.</div>}
        basic
        wide
      />
      <Table
        ariaLabel="Packages"
        columns={tableColumns}
        data={installedPackages ?? []}
        emptyMessage="No packages found."
        textAlign="left"
        color="blue"
        loading={isLoadingTeam || isLoadingInstalledPackage}
      />
    </>
  )
}
