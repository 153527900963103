import { omit } from 'lodash'
import { PackageVersionQueryResponse } from '../queries/packages/GetPackageVersionQuery'
import { PackagesQueryResponse } from '../queries/GetPackagesInfiniteQuery'
import { PackageTenantQuery } from '../views/package/services/PackageTenantCountUtils'
import { PackageTenantQueryResponse } from '../queries/packages/GetPackageTenantsQuery'
import { regionalApiServer } from './Endpoints'
import { IRegionInfo } from './Regions'

export const enum IPackageType {
  PRODUCT = 'PRODUCT',
  EXTENSION = 'EXTENSION',
  CUSTOM = 'CUSTOM',
  TEST = 'TEST'
}

export const enum IPackageOwnerType {
  SKEDULO = 'SKEDULO',
  CUSTOMER = 'CUSTOMER'
}

export const enum IPackageStatus {
  ALPHA = 'ALPHA',
  BETA = 'BETA',
  GA = 'GA',
  DEPRECATED = 'DEPRECATED'
}

export interface IPackageProperties {
  status: IPackageStatus
  type: IPackageType
  customerId?: string | null
  custom: object
  lastModifiedBy: string
  lastModifiedAt: string
}

export interface IPackage {
  packageName: string
  latestVersionDescription: string
  createdAt: string
  createdBy: string
  latestCreatedAt: string
  latestCreatedBy: string
  latestVersion: string
  ownerType: IPackageOwnerType
  properties: IPackageProperties
}

export interface IPackageTeam {
  id: string
  name: string
  customerName: string
  customerId: string
  owner: string
  environment: string
}

export interface IPackageTenant {
  tenantId: string
  deploymentId: string
  packageName: string
  packageVersion: string
  status: string
  createdAt: string
  createdBy: string
  completedAt: string
  totalTenants: number
  team?: IPackageTeam
}

export interface Artifact {
  type: string
  identifier: string
}

export interface IPackageVersion {
  packageName: string
  version: string
  createdBy: string
  createdAt: string
  description: string
  sourceUrl: string
  artifacts: Artifact[]
}

export interface IPackageTenantCount {
  packageName: string
  packageVersion?: string
  count: number
}

export interface IPackageWithTenantCount extends IPackage {
  count: number
}

export interface IPackageVersionWithTenantCount extends IPackageVersion {
  count: number
}

export interface PackageSearchParams {
  query?: string
  next?: string
}

export interface PackageVersionParams {
  query?: string
  next?: string
}

export interface PackageTenantParams {
  currentPage: number
  pageSize: number
  packageName?: string
  tenantId?: string
  packageVersion?: string
  createdBy?: string
  completedAt?: string
}

export interface PackageInstallationRequest {
  packageName: string
  version: string
  tenantIds: string | string[]
}

export interface PackageInstallationStatus {
  tenantId: string
  taskId: string
}

export interface PackageInstallationResponse {
  packageName: string
  version: string
  status: PackageInstallationStatus[]
}

export interface PackageInstallationResponseTenant {
  createdDate: string
  done: boolean
  id: string
  messages: []
  status: string
  success: boolean
}

export interface InstalledPackageParams {
  tenantId: string | undefined
  includeAllStatus: boolean
  includeDev: boolean
}

export interface InstalledPackage {
  tenantId: string
  deploymentId: string
  packageName: string
  packageVersion: string
  status: string
  createdAt: string
  createdBy: string
  completedAt?: string
}

export interface DeploymentValidationResult {
  message?: string
}

export type PatchPackage = Partial<IPackage>

export const countAllPackageTenants = (
  regions: IRegionInfo[],
  tenantQueries: PackageTenantQuery[]
): Promise<IPackageTenantCount[]> => {
  const regionNames = regions.map(region => region.region)
  return Promise.all(regionNames.map(regionName => countPackageTenant(regionName, tenantQueries))).then(
    packageTenantCounts => concatPackageCounts(packageTenantCounts)
  )
}

export const concatPackageCounts = (input: IPackageTenantCount[][]): IPackageTenantCount[] => {
  const result: Record<string, IPackageTenantCount> = {}

  for (const packageArray of input) {
    for (const packageCount of packageArray) {
      const { packageName, packageVersion, count } = packageCount
      const key = packageName + '' + packageVersion

      if (result[key]) {
        // If the package already exists in the result, add the count
        result[key].count += count
      } else {
        // If the package doesn't exist, create a new entry
        result[key] = { packageName, count, ...(packageVersion && { packageVersion }) }
      }
    }
  }

  return Object.values(result)
}

export const updatePackageProperties =
  (name: string) =>
  (patchProperties: Partial<IPackageProperties>): Promise<IPackageProperties> =>
    regionalApiServer('US')
      .patch(`/package-registry/internal/package/properties?packageName=${encodeURIComponent(name)}`, patchProperties)
      .then(response => response.data.result)

export const countPackageTenant = (
  regionName: string,
  tenantQueries: PackageTenantQuery[]
): Promise<IPackageTenantCount[]> =>
  regionalApiServer(regionName.toUpperCase())
    .post('/artifacts/package-deployments/internal/count', tenantQueries)
    .then(response => response.data.result)

export const getPackages = (params: PackageSearchParams): Promise<PackagesQueryResponse> =>
  regionalApiServer('US')
    .get('/package-registry/internal/packages/search', {
      params: {
        ...(params.query && { query: params.query }),
        ...(params.next && { next: params.next })
      }
    })
    .then(response => response.data)

export const getPackageByName = (packageName: string): Promise<IPackage> =>
  regionalApiServer('US')
    .get('/package-registry/internal/packages', {
      params: {
        ...(packageName && { packageName })
      }
    })
    .then(response => response.data.result)

export const getPackageVersions = (
  packageName: string,
  params: PackageVersionParams
): Promise<PackageVersionQueryResponse> =>
  regionalApiServer('US')
    .get('/package-registry/internal/package-versions/search', {
      params: {
        packageName,
        ...(params.query && { query: params.query }),
        ...(params.next && { next: params.next })
      }
    })
    .then(response => response.data)

export const getPackageVersionBy = (packageName: string, version: string): Promise<IPackageVersion> =>
  regionalApiServer('US')
    .get('/package-registry/internal/package-versions', {
      params: {
        ...(packageName && { packageName }),
        ...(version && { version })
      }
    })
    .then(response => response.data.result)

export const getPackageTenants = (params: PackageTenantParams, region: string): Promise<PackageTenantQueryResponse> =>
  regionalApiServer(region)
    .get('/artifacts/package-deployments/internal/search', {
      params: {
        currentPage: params.currentPage,
        pageSize: params.pageSize,
        ...(params.tenantId && { tenantId: params.tenantId }),
        ...(params.packageName && { packageName: params.packageName }),
        ...(params.packageVersion && { packageVersion: params.packageVersion })
      }
    })
    .then(response => response.data)

export const installPackage = (
  request: PackageInstallationRequest,
  region: string,
  token?: string
): Promise<PackageInstallationResponse | PackageInstallationResponseTenant> =>
  token ? installPackageTenant(request, region, token) : installPackageInternal(request, region)

export const installPackageInternal = (
  request: PackageInstallationRequest,
  region: string
): Promise<PackageInstallationResponse> =>
  regionalApiServer(region)
    .post('/artifacts/packages/internal/registered/deploy', request)
    .then(response => response.data.result)

export const installPackageTenant = (
  request: PackageInstallationRequest,
  region: string,
  token?: string
): Promise<PackageInstallationResponseTenant> =>
  regionalApiServer(region, token)
    .post('/artifacts/packages/registered/deploy', omit(request, ['tenantIds']))
    .then(response => response.data.result)

export const getInstalledPackage = (params: InstalledPackageParams, region: string): Promise<InstalledPackage[]> =>
  regionalApiServer(region)
    .get('/artifacts/package-deployments/internal/deployed', {
      params: {
        tenantId: params.tenantId,
        includeDev: params.includeDev,
        includeAllStatus: params.includeAllStatus
      }
    })
    .then(response => response.data.result)

export const checkDeploymentValidity = async (
  packageName: string,
  version: string,
  tenantId: string,
  region: string
): Promise<DeploymentValidationResult> =>
  regionalApiServer(region)
    .get('artifacts/packages/internal/registered/validate', {
      params: {
        packageName,
        version,
        tenantId
      }
    })
    .then(response => response.data)
